html, body {
  display: flex;
  justify-content: center;
  align-items: center;
  overscroll-behavior: none;
  overflow: hidden;
  background: #0f0e17;
}
#canvas {
  width: 400px;
  height: 400px;
  border: 1px solid #f69664;
  border-radius: 3px;
  padding: 2px;
}

/* On screens that are 992px wide or less, the background color is blue */
@media screen
    and (max-width: 992px)
    and (orientation: portrait) {
  #canvas {
    width: 800px;
    height: 800px;
  }
}